import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/Anne/Sites/transmasc/src/components/markdown.js";
import Seo from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="how to be transmasc: some helpful resources" description="Are you transmasculine and trying to work on your masculine traits? Here’s what I’ve found helpful on my own journey." mdxType="Seo" />
    <h1><strong parentName="h1">{`Being Transmasculine:`}</strong>{` Helpful Resources`}</h1>
    <p>{`So… you’re transmasculine (transmasc for short). Nice. Same.`}</p>
    <p>{`Keep in mind there is no one way to be transmasculine. I, personally, am nonbinary and identify more strongly with masculine traits, but in embracing this identity have come to be less resentful of my feminine traits, as well. I write this for my fellow transmascs wishing to present more masculine while still maintaining a well-rounded, healthy personhood.`}</p>
    <p>{`I have tried to include all the resources I’ve found interesting or helpful along my journey, along with some of my own experiences and commentary.`}</p>
    <h2>{`Names & Pronouns`}</h2>
    <p>{`Coming soon.`}</p>
    <hr></hr>
    <h2>{`Presentation`}</h2>
    <p>{`There are several elements of masculine presentation, including body shape, fashion, and vocals.`}</p>
    <h3>{`General Appearance`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ftmguide.rassaku.net/guide/index.htm"
        }}>{`The FTM’s Complete Illustrated Guide to Looking Like a (Hot) Dude`}</a></li>
    </ul>
    <h3>{`Masculine Voice Training`}</h3>
    <p>{`Tips on how to get a lower, louder, more resonant voice.`}</p>
    <ul>
      <li parentName="ul">{`Solid Introductory Voice Training Resources - `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/transvoice/comments/mgaci7/solid_introductory_voice_training_resources/"
        }}>{`Reddit`}</a></li>
      <li parentName="ul">{`Romeo’s Transmasculine Voice Training Guide - `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/transvoice/comments/ni2igv/romeos_trans_masculine_voice_training_guide/"
        }}>{`Reddit`}</a>{` - `}<a parentName="li" {...{
          "href": "https://docs.google.com/document/d/1JOr3wMophYTkRg8vQ8kBRCkJ6a9nPlmmzjJYZKBk-G0/edit"
        }}>{`Google Doc`}</a></li>
    </ul>
    <p>{`More coming soon.`}</p>
    <hr></hr>
    <h2>{`Mental Health`}</h2>
    <p>{`Coming soon.`}</p>
    <hr></hr>
    <h2>{`Hobbies`}</h2>
    <p>{`Coming soon.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      