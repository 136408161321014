import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

export const Layout = ({children}) => {
  return (
    <div className="flex flex-col w-screen max-w-full	min-h-screen bg-zinc-50 text-zinc-900">
      <div className="bg-amber-300 py-5 3xl:px-0 px-5">
        <div className="container mx-auto text-center">
          <div className="leading-none">
            <div className="font-bold">TransMasc</div>
            <div className="text-xs my-1">•••</div>
            <div className="font-serif italic text-2xl leading-none">
              helpful resources
            </div>
          </div>
        </div>
      </div>
      <main className="flex-grow">{children} </main>{" "}
      <div className="bg-rose-600 text-white py-10">
        <div className="container mx-auto 3xl:px-0 px-5">
          <p className="text-xs text-center max-w-md mx-auto">
            <a
              rel="license"
              href="http://creativecommons.org/licenses/by-nc/4.0/"
              target="_blank"
            >
              <img
                alt="Creative Commons License"
                style={{borderWidth: 0}}
                src="https://i.creativecommons.org/l/by-nc/4.0/88x31.png"
                className="mx-auto"
              />
            </a>
            <br />
            This work is licensed under a{" "}
            <a
              rel="license"
              href="http://creativecommons.org/licenses/by-nc/4.0/"
              target="_blank"
            >
              Creative Commons Attribution-NonCommercial 4.0 International
              License
            </a>
            .
          </p>
          <p className="text-xs text-center max-w-md mx-auto pb-2">
            This site does not use cookies or tracking.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Layout;
