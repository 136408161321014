import * as React from "react";
import {Layout} from "../components/layout";

// markup
export default ({children}) => {
  return (
    <Layout>
      <div className="markdown container mx-auto max-w-4xl py-10 3xl:px-0 px-5">
        {children}
      </div>
    </Layout>
  );
};
